@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&family=Space+Grotesk&display=swap');


* {
  /* border: 1px solid red; */
  padding: 0px;
  margin: 0px;

}

body {
  font-family: 'Space Grotesk', sans-serif;
}

.brand-logo {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-bottom: 20px;
  /* margin-left: 50px; */
  padding: 1rem;
  text-align: center;
}

.brandLogo {
  width: 500px
}

.text-container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  color: #065e71;
  flex-direction: column;
  font-family: 'Josefin Sans', sans-serif;
  text-align: center;
  position: relative;
  /* padding-left: 50px; */
  animation: fadeIn 3s ease-in-out forwards;
  padding: 1rem;
}

.top-half,
.bottom-half {
  /* width: 90%; */
  font-size: 1.8rem;
  /* position: absolute; */
  overflow: hidden;
  z-index: 100;
  animation: slide-up 2s ease-in-out;
}

.top-half {
  top: 0px;
  margin-bottom: 1rem;
}

.bottom-half {
  font-size: 3.6rem;
  font-weight: bold;
  top: 60px;
}

.image-container {
  /* margin-top: 180px; */
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0% 4%;
  opacity: 0;
  animation: fadeInImage 2s ease-in-out forwards;
}

.image-container img {
  width: 40vw;
}

.receive {
  width: 55%;
  text-align: justify;
  margin: auto;
  font-size: 1.8rem;
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.about {
  margin-top: 4%;
  padding: 15px
}

.about>h2 {
  color: #1c72f1;
  font-size: 2.5rem;
  margin-bottom: 10px
}

.about>p {
  text-align: justify;
}

.titles {
  margin: 10px
}

.title-head {
  color: #e88b21
}

.title-description {
  color: #23678d;
  text-indent: 50px;
  font-weight: 700;
}

.leadership {
  padding: 15px
}

.leadership h2 {
  font-size: 2.5rem;
  color: #1c72f1;
}

.leadership li {
  margin: 12px 3px;
  width: fit-content;
  list-style-type: '👉';
  padding-inline-start: 1ch;
  font-size: 15px;
  /* Initial font size */
  transition: font-size 3s 0.6s;
}

.leadership li:hover {
  font-size: 18px;
  /* Increase the font size on hover to 20px */

}

@media (max-width:992px) {
  .bottom-half {
    top: 40px;
  }

  .image-container img {
    width: 50vw;
  }

  .receive {
    width: 45vw;
    font-size: 1.2rem;
  }

  .about {
    padding: 20px 15px
  }
}

@media (max-width:768px) {
  .top-half {
    font-size: 1.3rem;
  }

  .bottom-half {
    font-size: 2.3rem;
  }

  .image-container {
    margin: auto;
    /* margin-top: 100px; */
    margin-top: 40px;
    width: 80%;
    flex-direction: column;
  }

  .image-container img,
  .receive {
    width: 79vw;
  }
}

@media (max-width:430px) {

  .brandLogo {
    width: 250px;
  }

  .text-container {
    /* padding-left: 5px; */
  }

  .top-half {
    font-size: 1.4rem;
    top: 0px
  }

  .bottom-half {
    top: 40px;
    font-size: 2.2rem;
  }

  .image-container {
    width: 90%
  }

  .image-container img {
    padding: 0%;
    width: 90vw;
  }

  .receive {
    width: 100%;
  }


  .about>h2 {
    color: #1c72f1;
    font-size: 1.6rem;
  }

  .titles {
    margin-bottom: 25px;
  }

  .title-head {
    font-size: 1rem;
  }

  .title-description {
    margin-top: 3px;
    font-size: 0.9rem;
  }

  .leadership h2 {
    font-size: 1.2rem;
  }

}